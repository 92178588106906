<template>
  <div ref="pdfContent" class="pdf-content">
    <CCard>
      <CCardHeader class="float-right no-print">
        <div class="float-right no-print">
          <a href="#" class="btn btn-sm btn-info ml-1" @click="printContent">
            <CIcon name="cil-print" class="mr-1" /> Print
          </a>
        </div>
      </CCardHeader>
      <CCardBody>
        <div class="header">
          <CRow class="justify-content-between align-items-center">
            <CCol xs="4" sm="3" md="2">
              <img
                :src="getImageUrl()"
                alt="Logo Syarikat"
                class="img-fluid logo-image"
              />
            </CCol>
            <CCol>
              <h1>{{ company.name }}</h1>
              <p>{{ company.tagLine }}</p>
              <p>{{ "(" + company.regNo + ")" }}</p>
              <p v-html="formatAddress(company.address)"></p>
              <p>Tel: {{ company.phone }}</p>
            </CCol>
          </CRow>
          <hr class="thick-hr" />
        </div>
        <div class="document-details">
          <CRow>
            <CCol md="6">
              <p><strong>Date:</strong> {{ getCurrentDate() }}</p>
              <p>Dear Sir/Mdm,</p>
              <p>
                <strong>Acknowledgement submission document:</strong>
                {{ getPlateNo() }}
              </p>
              <p><strong>Bank:</strong> {{ getLoanBankName() }}</p>
              <p v-html="formatAddress(getLoanBankAddress())"></p>
            </CCol>
            <CCol md="6" class="text-right">
              <p><strong>No:</strong> {{ stock.stockNo }}</p>
            </CCol>
          </CRow>
        </div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>No</th>
              <th>Description</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.remarks }}</td>
            </tr>
          </tbody>
        </table>
        <div class="footer">
          <p>Thank you</p>
          <!-- <p>P/S: {{ report.remarksFooter }}</p> -->
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "WidgetsReportDocumentSubmission",
  props: {
    stock: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
      validator: (value) => {
        return ["name", "tagLine", "address", "phone", "logoUrl"].every(
          (prop) => prop in value
        );
      },
    },
    bank: {
      type: Object,
      required: true,
    },

    // report: {
    //   type: Object,
    //   required: true,
    //   validator: (value) => {
    //     return [
    //       "date",
    //       "documentNumber",
    //       "bank",
    //       "reportNumber",
    //       "items",
    //       "remarksFooter",
    //     ].every((prop) => prop in value);
    //   },
    // },
  },
  data: () => {
    return {
      items: [
        { description: "INVOICE", remarks: "✔" },
        { description: "HANDLING FEE LETTER", remarks: "✔" },
        { description: "INDEMNITY LETTER", remarks: "✔" },
        { description: "ORIGINAL @ COPY REGN. CARD", remarks: "✔" },
        { description: "INSURANCE", remarks: "✔" },
        { description: "COPY ROAD TAX", remarks: "✔" },
        { description: "TRANSFER NAME ORIGINAL RECEIPT", remarks: "✔" },
        { description: "COPY AP", remarks: "✔" },
        { description: "COPY B2", remarks: "✔" },
        { description: "B7 (ORIGINAL)", remarks: "✔" },
        { description: "IC HIRER", remarks: "✔" },
        { description: "DELIVERY ORDER", remarks: "✔" },
      ],
    };
  },
  methods: {
    printContent() {
      const printContents = this.$refs.pdfContent.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(currentDate.getDate()).padStart(2, "0");

      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    },
    getPlateNo() {
      try {
        return this.stock.registration.vehicleRegistrationNumber;
      } catch (error) {
        return "N/A";
      }
    },
    getLoanBankAddress() {
      try {
        return this.bank.address;
      } catch (error) {
        return "N/A";
      }
    },
    getLoanBankName() {
      try {
        return this.bank.name;
      } catch (error) {
        return "N/A";
      }
    },
    removeTrailingSlash(str) {
      if (str.endsWith("/")) {
        return str.slice(0, -1);
      }
      return str;
    },
    getImageUrl() {
      var url = this.removeTrailingSlash(apiUrl) + this.company.logoUrl;
      return url;
    },
    formatAddress(address) {
      return address.replace(/\n/g, "<br />");
    },
    saveAsPDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: [0.5, 0.5, 0.5, 0.5], // Top, right, bottom, left
        filename: "DocumentSubmissionReport.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };
      html2pdf().from(element).set(options).save();
    },
    printContent() {
      const printContents = this.$refs.pdfContent.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.thick-hr {
  border: 1px solid #000;
  margin: 10px 0;
}
.pdf-content {
  margin: 0;
  padding: 0;
  font-size: 12px; /* Adjust font size to fit content */
}

.logo-image {
  max-width: 100%; /* Adjust the width to fit the print layout */
}
.footer p {
  margin: 0;
}
@media print {
  .no-print {
    display: none !important;
  }
}
</style>
