<template>
  <div ref="pdfContent" class="pdf-content">
    <CCard>
      <CCardHeader>
        
        <div class="float-right no-print">
          <!-- <a href="#" class="btn btn-sm btn-info" @click="saveAsPDF">
            <CIcon name="cil-save" /> Save as PDF
          </a> -->
          <a href="#" class="btn btn-sm btn-info ml-1" @click="printContent">
            <CIcon name="cil-print" class="mr-1" /> Print
          </a>
        </div>
      </CCardHeader>
      <CCardBody>
        <div>
          <CRow class="justify-content-between align-items-center">
            <CCol xs="4" sm="3" md="2">
              <img :src="getImageUrl()" alt="Logo Syarikat" class="img-fluid logo-image" />
            </CCol>
            <CCol >
              <h1>{{ company.name }} </h1>
              <p>{{ company.tagLine }}</p>
              <p>{{ '(' + company.regNo +')'}}</p>
              <p v-html="formatAddress(company.address)"></p>
              <p>Tel: {{ company.phone }}</p>
            </CCol>
          </CRow>
          <hr class="thick-hr" />
          <h3 class="text-center">Finance · Used Cars · Insurance</h3>
          <br/>
          <p>{{ bank.name }}</p>
          <p v-html="formatAddress(bank.address)"></p>
          <br/>
          <p>Tuan/Puan,</p>
          <p>
            <strong>
              Per: Nombor Akaun: {{ bank.accountNo }} ({{ bank.accountName }})
            </strong>
          </p>
          <hr/>
          <p>
            Dengan segala hormatnya merujuk kepada perkara di atas. Dengan ini, dimaklumkan bahawa pihak kami mempunyai Akaun dengan {{ company.bankName }}. Segala urusniaga hendaklah dimasukkan terus ke dalam akaun seperti yang tertera di atas untuk tujuan semakan MITI dan akaun kami.
          </p>
          <p>
            Untuk makluman pihak tuan, di bawah ini kami sertakan nama dan nombor pendaftaran bagi kenderaan tersebut.
          </p>
          <p>
            <table class="table-borderless">
              <tr>
                <td>Nama:  </td>
                <td>  <strong>{{ getCustomerName() }}</strong></td>
              </tr>
              <tr>
                <td>No. Pendaftaran:  </td>
                <td>  <strong>{{  getPlateNo() }}</strong></td>
              </tr>
            </table>
          </p>
          <p>Kerjasama daripada pihak tuan amatlah di hargai.</p>
          <p>Sekian, terima kasih.</p>
          <br/>
          <p>Yang benar,</p>
          <br/>
          <br/>
          <br/>
          <div class="dotted-line"></div>
          <p><strong>{{ company.name }}</strong></p>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "WidgetsReportAccountNotice",
  props: {
    company: {
      type: Object,
      required: true,
      validator: (value) => {
        return [
          "name",
          "regNo",
          "tagLine",
          "address",
          "phone",
          "logoUrl",
          // "bankAccountName",
          // "bankAccountNo",
          // "bankName",
          // "bankAddress",
        ].every((prop) => prop in value);
      },
    },
    bank: {
      type: Object,
      required: true,
    },
    stock: {
      type: Object,
      required: true,
    },

    // customer: {
    //   type: Object,
    //   required: true,
    //   validator: (value) => {
    //     return ["name", "registrationNumber"].every((prop) => prop in value);
    //   },
    // },
  },
  methods: {
    getPlateNo() {
      try {
        return this.stock.registration.vehicleRegistrationNumber;
      } catch (error) {
        return "N/A";
      }
    },
    getCustomerName() {
      try {
        return this.stock.sale.customer.name;
      } catch (error) {
        return "N/A";
      }
    },
    removeTrailingSlash(str) {
      if (str.endsWith("/")) {
        return str.slice(0, -1);
      }
      return str;
    },
    getImageUrl() {
      var url = this.removeTrailingSlash(apiUrl) + this.company.logoUrl;
      // console.log(url);
      return url;
    },
    formatAddress(address) {
      return address.replace(/\n/g, "<br />");
    },
    saveAsPDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: [0.5, 0.5, 0.5, 0.5], // Top, right, bottom, left
        filename: "Invoice_90-98792.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };
      html2pdf().from(element).set(options).save();
    },
    printContent() {
      const printContents = this.$refs.pdfContent.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.thick-hr {
  border: 1px solid #000; /* Adjust the value to increase the weight */
}
.table-borderless td,
.table-borderless th {
  border: 0; /* Remove borders */
}
.dotted-line {
  border-bottom: 1px dotted #000;
  width: max-content;
  margin: 0 auto;
  padding-bottom: 5px;
}
.pdf-content {
  margin: 0;
  padding: 0;
  font-size: 12px; /* Adjust font size to fit content */
}
.logo-image {
  max-width: 100%; /* Adjust the width to fit the print layout */
}
@media print {
  .no-print {
    display: none !important;
  }
}
</style>
